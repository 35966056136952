import React from 'react'
import Layout from '../components/layout'
import { Container, Typography, Grid, Box, Button } from '@material-ui/core'

import Footer from '../components/Footer'
import { HelmetProvider } from 'react-helmet-async'
import { graphql } from 'gatsby'
import TreatmentsCards from '../components/TreatmentsCards'
import SEO from '../components/SEO'

const NotFoundPage: React.FC = (props) => {
  return (
    <HelmetProvider>
      <Layout>
        <SEO title="Pagina niet gevonden" />
        <Box my={12}>
          <Container maxWidth="lg">
            <Box my={50}>
              <Grid container justify="center" alignItems="center">
                <Grid item>
                  <Typography component="h1" variant={`h3`}>
                    Oeps !, Ai!, Krak!
                  </Typography>
                  <Typography variant={'body1'}>
                    Deze pagina kunnen we niet terugvinden.
                  </Typography>
                  <Button variant="contained" color="secondary">
                    Terug naar de home pagina
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <TreatmentsCards treatments={props.data.allSanityTreatment.edges} />
          </Container>
          <Footer />
        </Box>
      </Layout>
    </HelmetProvider>
  )
}

export const pageQuery = graphql`
  query ErrorPageQuery {
    sanitySiteSettings {
      siteUrl
      title
      description
      logo {
        asset {
          fixed(width: 125) {
            src
          }
        }
      }
      heroImage {
        asset {
          fluid(maxWidth: 2160) {
            src
          }
        }
      }
    }
    allSanityTreatment(sort: { fields: order, order: ASC }) {
      edges {
        node {
          order
          title
          slug {
            current
          }
          shortDescription
          background {
            asset {
              fluid(maxWidth: 250) {
                ...GatsbySanityImageFluid
              }
            }
          }
          subTitle
          _rawBody
          people {
            fullName
            slug {
              current
            }
          }
        }
      }
    }
  }
`

export default NotFoundPage
