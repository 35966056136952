/* eslint-disable react/display-name */
import React from 'react'
import { makeStyles, Typography, Box } from '@material-ui/core'
import { Tags } from './Tags'
import { Link } from 'gatsby-theme-material-ui'
import clsx from 'clsx'
import {
  GatsbySanityImageFluidFragment,
  Maybe,
  SanityPerson,
  SanitySlug,
  SanityTreatment,
} from '../../@types/graphql-types'
const useStyles = makeStyles((theme) => ({
  treatments: {
    boxSizing: 'border-box',
    display: 'flex',
    margin: theme.spacing(0, 0, 0),
    padding: theme.spacing(5, 0),
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(-2),
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(-3),
    },
    [theme.breakpoints.up('lg')]: {
      // half of the whole screen minus container width plus padding, minus one px to prevent scrollbar
      marginRight: `calc((100vw - 1280px + ${theme.spacing(4) - 1}px) / -2)`, // QUICK MATHS
    },
  },
  title: {},
  shortDesc: {},
  cardGrid: {
    padding: theme.spacing(6),
    display: 'flex',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      background: `linear-gradient(270deg, #0074E0 0%, #3083CF 39.06%, #309FCF 96.88%);`,
    },
  },
  card: {
    '--minWidth': '300px',
    display: 'flex',
    minWidth: 'var(--minWidth)',
    minHeight: '300px',
    padding: theme.spacing(4),
    flexDirection: 'column',
    overflow: 'hidden',
    background: theme.palette.background.paper,
    borderRadius: '16px',
    boxShadow: `-1rem 0 3rem #000 `,
    margin: 0,
    position: 'relative',
    top: 0,
    transition: '.2s',
    [theme.breakpoints.down('md')]: {
      '--minWidth': '220px',
    },
    [theme.breakpoints.down('sm')]: {
      scrollSnapAlign: 'start',
      '--minWidth': '190px',
    },
    '&:hover, &:focus-within': {
      marginBottom: '1rem',
      marginTop: '-1rem',
      marginRight: 'calc(var(--minWidth) / 2.5)',
    },
    '&:not(:first-child)': {
      marginLeft: 'calc(var(--minWidth) / -2)',
    },

    '&:hover~$root': {
      transform: 'translateX(130px)',
    },
  },
  moreTreatments: {
    justifyContent: 'center',
    minHeight: 'auto',
  },
  moreTreatmentsTitle: {
    fontSize: '1.2rem',
    fontWeight: 'normal',
  },
  header: {
    backgroundImage:
      'linear-gradient(270deg, #0074E0 0%, #3083CF 39.06%, #309FCF 96.88%);',
    position: 'relative',
    borderRadius: '16px',
    padding: theme.spacing(4),
    margin: theme.spacing(6, 0),
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    flex: '0 0 250px',
    [theme.breakpoints.down('md')]: {
      writingMode: 'vertical-rl',
      transform: 'rotate(180deg)',
      flex: 0,
      width: '110px',
    },
  },
  blockTitle: {
    fontSize: '1.6rem',
    margin: theme.spacing(0, 0, 1),
  },
}))

interface TreatmentProps {
  treatments: Array<{
    node: Pick<
      SanityTreatment,
      'order' | 'title' | 'shortDescription' | 'subTitle' | '_rawBody'
    > & {
      slug?: Maybe<Pick<SanitySlug, 'current'>>
      background?: Maybe<{
        asset?: Maybe<{ fluid?: Maybe<GatsbySanityImageFluidFragment> }>
      }>
      people?: Maybe<
        Array<
          Maybe<
            Pick<SanityPerson, 'fullName'> & {
              slug?: Maybe<Pick<SanitySlug, 'current'>>
            }
          >
        >
      >
    }
  }>
}
const TreatmentsCards: React.FC<TreatmentProps> = ({ treatments }) => {
  const classes = useStyles(undefined)
  return (
    <Box className={classes.treatments}>
      <Box component="header" className={classes.header}>
        <Typography component="h2" className={classes.blockTitle}>
          Behandelingen
        </Typography>
      </Box>
      <Box className={classes.cardGrid}>
        {treatments.map((treatment, key) => (
          <Box component="article" className={classes.card} key={key}>
            <Link
              to={`behandelingen/#${treatment.node.slug.current}`}
              underline="none"
              style={{ display: 'inline' }}
            >
              <Typography
                className={classes.title}
                variant="h5"
                style={{ display: 'inline' }}
              >
                {treatment.node.title}
              </Typography>
            </Link>
            <Typography className={classes.shortDesc} variant="subtitle1">
              {treatment.node.subTitle}
            </Typography>
            <Tags tags={treatment.node.people} />
          </Box>
        ))}
        <Box
          component="article"
          flexGrow={1}
          className={clsx(classes.card, classes.moreTreatments)}
        >
          <Link to={`/behandelingen`}>
            <Typography className={classes.moreTreatmentsTitle} variant="h5">
              Meer behandelingen &#8594;
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

export default TreatmentsCards
